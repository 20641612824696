<template>
  <Modal
    modalId="cancel-assigning"
    :no-close-on-backdrop="false"
    :hide-header="true"
    :hide-footer="true"
  >
    <div>
      <div class="row">
        <div class="col-md-12">
          <div class="fs-30px fw-700">
            {{ $t("Cancel Assigning") }}
          </div>
          <p class="text-gray mt-2">
            {{ $t("What is the reason you want to cancel this assigning?") }}
          </p>
        </div>
      </div>
      <p
        class="text-danger fw-bold text-capitalize"
        v-if="getErrors.cancelAssigning && getErrors.form.length"
      >
        {{ getErrors.cancelAssigning }}
      </p>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim>
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <RadioInput
            name="cancel_reason"
            v-model="form.cancel_reason"
            rules="required"
            classes="col-sm-6 col-12"
            :options="options"
          />

          <div class="mt-3">
            <label>{{ $t("Reason") }}</label>
            <Textarea
              name="reason"
              v-model="form.reason"
              rules="required_if:cancel_reason,other"
              :disabled="form.cancel_reason != 'other'"
            />
          </div>
          <div>
            <b-form-checkbox
              id="checkbox-1"
              v-model="form.report_to_admin"
              name="checkbox-1"
              :value="true"
              :unchecked-value="false"
            >
              {{ $t("I want to report this candidate to Eerlijktehuur") }}
            </b-form-checkbox>
          </div>

          <div class="mt-2" v-if="form.report_to_admin">
            <label class="text-dark">{{ $t("Reason of reporting:") }}</label>
            <Textarea name="reason" v-model="form.report_admin_reason" rules="required" />
          </div>

          <div class="row mt-4">
            <div class="col-md-12">
              <BButton
                :disabled="getLoadings.cancelAssigning"
                type="submit"
                block
                variant="primary"
              >
                {{ $t("Submit reason") }}
                <b-spinner
                  v-if="getLoadings.cancelAssigning"
                  label="Spinning"
                  small
                  variant="white"
                ></b-spinner>
              </BButton>
            </div>
            <div class="col-md-12 pt-0">
              <button
                class="btn btn-white btn-block"
                type="button"
                @click="$bvModal.hide('cancel-assigning')"
              >
                {{ $t("Cancel") }}
              </button>
            </div>
          </div>
        </b-form>
      </ValidationObserver>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Modal from "../../../components/ui/Modal.vue";
import { ValidationObserver } from "vee-validate";
import Textarea from "../../../components/ui/form/controls/Textarea.vue";
import RadioInput from "../../../components/ui/form/controls/RadioInput.vue";

export default {
  name: "CanceAssigningModal",
  props: ["assigning_id"],
  components: {
    Modal,
    ValidationObserver,
    Textarea,
    RadioInput,
  },
  data() {
    return {
      selectedRowId: null,
      tenant: null,
      form: {
        cancel_reason: "Candidate did not show up at viewing",
        reason: "",
        report_to_admin: false,
        report_admin_reason: "",
      },
      options: [
        {
          value: "Candidate did not show up at viewing",
          text: "Candidate did not show up at viewing",
        },
        { value: "other", text: "Other reason" },
      ],
    };
  },
  computed: {
    ...mapGetters("requestsAndAppointment", ["assignings", "listing", "user"]),
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
  },
  methods: {
    ...mapActions("requestsAndAppointment", ["cancelAssigning", "getAssignings"]),
    async onSubmit() {
      let data = { ...this.form };
      data.reason = data.cancel_reason == "other" ? data.reason : data.cancel_reason;
      data.assigning_id = this.assigning_id;
      await this.cancelAssigning(data);
      if (this.assigning_id) {
        localStorage.setItem(`assign_modal_id_${this.listing.id}`, "");
        this.$bvModal.hide("cancel-assigning");
      } else {
        this.$router.push({
          name: "AssigningTable",
          params: { listingId: this.listing.id },
        });
      }
    },
  },
};
</script>
<style lang="scss">
.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: $black !important;
}
</style>
