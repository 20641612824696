<template>
  <div>
    <ReportCandidateModal @report-candidate="candidateReport" />
    <AssignCandidateModal
      :currentUser="getSelectedRowData.user"
      v-if="getSelectedRowData"
      @assign="$emit('candidateAssign', $event)"
    />
    <CancelAssigningModal
      :assigning_id="getSelectedRowData.id"
      v-if="getSelectedRowData"
    />

    <Spinner v-if="getLoadings.assigning" />

    <Table
      :excludeFromClickOutside="[
        '#report-candidate-modal',
        '#assign-candidate-modal',
        '#cancel-assigning',
      ]"
      :rows="assignings"
      :columns="columns"
      @rowSelect="onRowSelect"
    >
      <template #custom-html-cell="{ field, value, row }">
        <!-- user -->
        <template v-if="field === 'user'">
          <span
            :data-content="$t('tennant')"
            class="text-capitalize"
            v-if="row.assigned === true"
          >
            {{ value.first_name }}
            {{ value.last_name }}
          </span>
          <span v-else>
            {{ row.userCode }}
          </span>
        </template>

        <!-- Identity -->
        <template v-else-if="field === 'identity' && value !== null">
          <TickBox v-if="value === true" />
          <TimesBox v-else />
        </template>

        <!-- Sufficient income -->
        <template v-else-if="field === 'sufficientIncome' && value !== null">
          <TickBox
            :id="`sufficientIncome-professional-${row.id}`"
            fill="#ffcb00"
            v-if="row.profesional_less_than_2"
          />
          <TickBox :id="`sufficientIncome-${row.id}`" fill="#0a9250" v-else-if="value" />
          <TimesBox :id="`notSufficientIncome-${row.id}`" v-else />

          <b-tooltip
            custom-class="custom-tooltip"
            :target="`sufficientIncome-professional-${row.id}`"
            placement="right"
          >
            Contains a tenant with less than 2 years entrepreneurship
          </b-tooltip>
          <b-tooltip
            custom-class="custom-tooltip"
            :target="`sufficientIncome-${row.id}`"
            placement="right"
          >
            Income sufficient
          </b-tooltip>
          <b-tooltip
            custom-class="custom-tooltip"
            :target="`notSufficientIncome-${row.id}`"
            placement="right"
          >
            Income not-sufficient
          </b-tooltip>
        </template>

        <!-- Credit check -->
        <template v-else-if="field === 'riskcode' && value !== null">
          <div
            class="wh-20px rounded-circle m-auto"
            :id="`riskcode-${value}-${row.id}`"
            :class="{
              'bg-primary': value == 1,
              'bg-warning': value == 2,
              'bg-danger': value == 3,
            }"
          ></div>
          <b-tooltip
            custom-class="custom-tooltip"
            :target="`riskcode-1-${row.id}`"
            placement="right"
          >
            Overeenkomst op basis van krediet wordt verantwoord geacht
          </b-tooltip>
          <b-tooltip
            custom-class="custom-tooltip"
            :target="`riskcode-2-${row.id}`"
            placement="right"
          >
            Person not found in EDR database
          </b-tooltip>

          <b-tooltip
            custom-class="custom-tooltip"
            :target="`riskcode-3-${row.id}`"
            placement="right"
          >
            Aangaan van een overeenkomst op basis van krediet geeft een verhoogd risico
          </b-tooltip>
        </template>

        <!-- Household -->
        <template v-else-if="field === 'householdComposition'">
          <Person
            :fill="getHouseholdColor(value)"
            :id="`household_${row.id}`"
            v-if="value === 'single'"
          />
          <People
            :id="`household_${row.id}`"
            :fill="getHouseholdColor(value)"
            v-else-if="value === 'couple'"
          />
          <Team
            :fill="getHouseholdColor(value)"
            :id="`household_${row.id}`"
            v-else-if="value === 'family'"
          />
          <Group :fill="getHouseholdColor(value)" :id="`household_${row.id}`" v-else />
          <b-tooltip
            custom-class="custom-tooltip"
            :target="`household_${row.id}`"
            placement="top"
          >
            <template v-if="value === 'single'">
              Single - {{ row.user.tenants.length }} main tenant(s)
            </template>
            <template v-else-if="value === 'couple'">
              Couple - {{ row.user.tenants.length }} main tenant(s)
            </template>
            <template v-else-if="value === 'family'">
              Family - {{ row.user.tenants.length }} main tenant(s)
            </template>
            <template v-else>
              Group occupancy - {{ row.user.tenants.length }} main tenant(s)
            </template>
          </b-tooltip>
        </template>

        <!-- KYC -->
        <template v-else-if="field === 'sanction'">
          <span
            v-if="row.sanction == 'red'"
            class="d-block listing-requests__availability-slots wh-20px rounded-circle bg-danger"
            :id="`kyc-${row.id}`"
          ></span>
          <span
            v-if="row.sanction == 'yellow'"
            class="d-block listing-requests__availability-slots wh-20px rounded-circle bg-warning"
            :id="`kyc-${row.id}`"
          ></span>
          <span
            v-if="row.sanction == 'green'"
            class="d-block listing-requests__availability-slots wh-20px rounded-circle bg-primary"
            :id="`kyc-${row.id}`"
          ></span>
          <b-tooltip
            custom-class="custom-tooltip"
            :target="`kyc-${row.id}`"
            placement="right"
            v-if="row.sanction == 'red'"
          >
            <ul class="px-4 py-2 mb-0 list-style-dots">
              <li v-for="flag in row.flags" :key="flag">{{ flag }}</li>
            </ul>
          </b-tooltip>
          <b-tooltip
            custom-class="custom-tooltip"
            :target="`kyc-${row.id}`"
            placement="right"
            v-if="row.sanction == 'green'"
          >
            No results found
          </b-tooltip>
        </template>

        <!-- Pets -->
        <template v-else-if="field === 'pets'">
          <b-tooltip
            custom-class="custom-tooltip"
            :target="`pets-${row.id}`"
            placement="right"
          >
            {{
              row.user.pets
                ? `${row.user.number_of_pets ?? ""} ${row.user.type_of_pets ?? ""}`
                : $t("No pets")
            }}
          </b-tooltip>
          <Paw
            :fill="listing.preferredAnimals == row.pets ? '#0a9250' : '#FE5F55'"
            :id="`pets-${row.id}`"
            class="ml-2"
          />
        </template>

        <!-- Tenant score -->
        <template v-else-if="field === 'score' && value !== null">
          <span
            class="fw-bold"
            :style="{
              color: getTenantScoreColor(value),
            }"
            >{{ value }}/10</span
          >
        </template>

        <!-- Empty state -->
        <span
          class="custom-vgt-table__empty rounded-circle d-block"
          v-else-if="!field || value == null"
        >
        </span>

        <!-- Default -->
        <span v-else>
          {{ value }}
        </span>
      </template>

      <template #custom-table-actions v-if="showAction">
        <div class="d-flex table-actions justify-content-end flex-column flex-sm-row">
          <button @click="assigningCancelled" class="btn btn-white">
            {{ $t("Cancel assigning") }}
            <b-spinner
              v-if="getLoadings.auth"
              small
              label="Spinning"
              variant="primary"
            ></b-spinner>
          </button>
          <BButton
            v-b-modal.report-candidate-modal
            class="ml-sm-3 ml-0 mt-3 mt-sm-0"
            :variant="selectedRowId ? 'danger' : 'white'"
            :disabled="!selectedRowId"
          >
            {{ $t("Report candidate") }}
          </BButton>
          <BButton
            class="ml-sm-3 mt-3 mt-sm-0"
            :variant="selectedRowId ? 'primary' : 'white'"
            @click="openAssignModal"
            :disabled="!getSelectedRowData || getSelectedRowData.assigned === true"
          >
            {{ $t("Assign candidate") }}
          </BButton>
        </div>
      </template>
    </Table>
  </div>
</template>
<script>
import Table from "@/components/ui/tables/Table.vue";
import Person from "@/components/svg/Person.vue";
import People from "@/components/svg/People.vue";
import Team from "@/components/svg/Team.vue";
import Paw from "@/components/svg/Paw.vue";
import Group from "@/components/svg/Group.vue";
import TickBox from "@/components/svg/TickBox.vue";
import TimesBox from "@/components/svg/TimesBox.vue";
import { mapActions, mapGetters } from "vuex";
import ReportCandidateModal from "./ReportCandidateModal.vue";
import AssignCandidateModal from "./AssignCandidateModal.vue";
import CancelAssigningModal from "../../../views/Broker/Listings/CancelAssigningModal.vue";
import Spinner from "../../ui/Spinner.vue";

export default {
  props: {
    showAction: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("requestsAndAppointment", ["assignings", "listing"]),
    ...mapGetters("loadingError", ["getLoadings"]),
    getSelectedRowData() {
      if (!this.selectedRowId) return null;
      return this.assignings.find((req) => req.id == this.selectedRowId);
    },
    listingId() {
      return this.$route.params.listingId;
    },
    columns() {
      return [
        {
          label: "User",
          field: "user",
          tooltip: "A simple tooltip",
          html: true,
        },
        {
          label: "Income",
          field: "sufficientIncome",
          html: true,
          type: "boolean",
        },
        {
          label: "Credit check",
          // label: "Col3",
          field: "riskcode",
          html: true,
          type: "boolean",
        },
        // {
        //   // label: this.assigningLocale.tableCols[6],
        //   label: "Col4",
        //   field: "validated",
        //   html: true,
        //   type: "boolean",
        // },
        {
          label: "KYC",
          //   label: "Col4",
          field: "sanction",
          html: true,
          //   type: "boolean",
        },
        {
          label: "Household",
          field: "householdComposition",
          html: true,
        },
        {
          label: "Pets",
          field: "pets",
          html: true,
        },
        {
          label: "Tenant score",
          field: "score",
          html: true,
        },

        {
          label: "User",
          field: "userCode",
          hidden: true,
        },
        {
          label: "assigned",
          field: "assigned",
          hidden: true,
        },
        {
          label: "id",
          field: "id",
          hidden: true,
        },
        {
          label: "Flags",
          field: "flags",
          hidden: true,
        },
        {
          field: "profesional_less_than_2",
          hidden: true,
        },
      ];
    },
  },
  data() {
    return {
      selectedRowId: null,
    };
  },
  components: {
    Table,
    Paw,
    Person,
    People,
    Team,
    TimesBox,
    TickBox,
    Group,
    ReportCandidateModal,
    AssignCandidateModal,
    CancelAssigningModal,
    Spinner,
  },
  methods: {
    ...mapActions("report", ["reportCandidate"]),
    ...mapActions("requestsAndAppointment", [
      "setAssignings",
      "getAssignings",
      "assignCandidate",
      "cancelAssigning",
      "validateAssigning",
      "updateStatus",
    ]),
    onRowSelect(rowId) {
      this.selectedRowId = rowId;
      console.log("onrow", this.selectedRowId);
      this.$emit("rowSelect", rowId);
    },
    async assigningCancelled() {
      // await this.cancelAssigning({ listingId: this.listing.id });
      await this.updateStatus("Candidate selection");
      this.$router.push(`/broker/listings/${this.listing.id}`);
    },
    getHouseholdColor(household) {
      if (!this.listing || !household) return "#0a9250";
      return this.listing.preferredHousehold
        .toLowerCase()
        .indexOf(household.toLowerCase()) > -1
        ? "#0a9250"
        : "#FE5F55";
    },
    getTenantScoreColor(value) {
      if (value <= 3) return "#FE5F55";
      if (value > 3 && value <= 6) return "#FFCB00";
      return "#0a9250";
    },
    async candidateReport(body) {
      body.user_id = this.selectedRowId;
      await this.reportCandidate(body);
      this.$bvModal.hide("report-candidate-modal");
    },
    openAssignModal() {
      this.$bvModal.show("assign-candidate-modal");
      localStorage.setItem(`assign_modal_id_${this.listingId}`, this.selectedRowId);
    },
  },
  async created() {
    await this.getAssignings({ listingId: this.listingId });
    if (this.listing.assigned) {
      this.$router.push({
        name: "ListingAssigned",
        params: { listingId: this.listing.id },
      });
    }
    let assign_modal_id = localStorage.getItem(`assign_modal_id_${this.listingId}`);
    if (assign_modal_id) {
      this.selectedRowId = assign_modal_id;
      setTimeout(() => {
        this.openAssignModal();
      }, 100);
    }
  },
};
</script>
