<template>
  <Modal modalId="assign-candidate-modal" :hide-header="true" :hide-footer="true">
    <div class="row">
      <div class="col-md-12">
        <div class="fs-30px text-black fw-700">
          {{ $t("Assign candidate(s)") }}
        </div>
        <p class="text-gray mt-2">
          {{
            $t(
              "You are about to assign this candidate. After assigning, all other candidates will receive the result by email."
            )
          }}
        </p>
      </div>
    </div>
    <div class="row mt-0">
      <div class="col-md-12">
        <div class="card bg-gray7">
          <div class="card-body">
            <strong>
              {{ $t("Who are the tenants?") }}
            </strong>
            <ul class="disc ml-4 mt-2" v-if="currentUser?.tenants">
              <li v-for="(tenant, key) in currentUser.tenants" :key="key">
                {{ tenant.name }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <p
      class="text-danger fw-bold text-capitalize"
      v-if="getErrors.assigning && getErrors.assigning.length"
    >
      {{ getErrors.assigning }}
    </p>
    <div class="row">
      <div class="col-md-12">
        <BButton variant="primary" block @click="onAssign(true)">
          {{ assignLocale.assign }}
          <b-spinner
            v-if="getLoadings.assigning"
            small
            label="Spinning"
            variant="white"
          ></b-spinner>
        </BButton>
      </div>
      <div class="col-md-12">
        <BButton
          variant="outline-danger"
          block
          @click="$bvModal.hide('assign-candidate-modal');$bvModal.show('cancel-assigning');"
        >
          {{ assignLocale.cancel }}
        </BButton>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/ui/Modal.vue";
import { mapGetters } from "vuex";
export default {
  props: {
    currentUser: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    assignLocale() {
      return this.$t("broker.assigningTable.assigningModal");
    },
  },
  components: {
    Modal,
  },
  methods: {
    onAssign(assigned) {
      this.$emit("assign", { assigned });
    },
  },
};
</script>

<style lang="scss">
.assign-modal {
  &__candiate {
    font-size: 1.875rem;
  }

  &__btn {
    width: 13.875rem;
  }

  &__comment {
    textarea {
      padding: 1rem 0.75rem;
    }
  }
}
</style>
